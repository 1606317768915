import {Card, CardBody, CardHeader, CardProps, Flex, Heading, IconButton, LinkBox, Text, VStack} from "@chakra-ui/react"
import {BarWithDeals, sortedDealsNullable} from "../../interfaces/IBar"
import {IDeal} from "../../interfaces/IDeal"
import {CardSubtitle} from "react-bootstrap";
import DealView from "./DealView";
import {useTranslations} from "next-intl";
import {Link} from "../../i18n/routing";
import {ReactNode} from "react";
import {EditIcon} from "@chakra-ui/icons";
import {LinkOverlayToDealOwner} from "./LinkOverlayToDealOwner";

export interface Props extends CardProps {
    bar: BarWithDeals,
    children?: ReactNode|undefined
}

export default function BarCard(props: Props) {
    const t = useTranslations("Home")
    return (
        <Card variant="filled" {...props} size="sm">
            <LinkBox>
                <CardHeader>
                    <Flex justifyContent="space-between" alignContent="top">
                        <LinkOverlayToDealOwner owner={props.bar}>
                            <VStack align="start" m={2}>
                                <Heading size='md'>{props.bar.name}</Heading>
                                <CardSubtitle><Text color="teal.500">{props.bar.address}</Text></CardSubtitle>
                            </VStack>
                        </LinkOverlayToDealOwner>
                        <Link href={{pathname: "/addbar/" + props.bar.id}} rel="nofollow">{<IconButton borderRadius="50%" aria-label={t("suggestEdit")} icon={<EditIcon/>}></IconButton>}</Link>
                    </Flex>
                </CardHeader>
                <LinkOverlayToDealOwner owner={props.bar}>
                    <CardBody>
                        <VStack spacing={5} align="stretch">
                            {sortedDealsNullable(props.bar)?.map((deal: IDeal) => <DealView key={deal.description} deal={deal}/>)}
                            {props.children !== undefined && props.children}
                        </VStack>
                    </CardBody>
                </LinkOverlayToDealOwner>
            </LinkBox>
        </Card>
    )
  }